import React from "react";
import { Navigation, Autoplay, FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import image1 from "../../Asset/message.png";
import image2 from "../../Asset/grammer.png";
import image3 from "../../Asset/time trap.png";
import image4 from "../../Asset/language barriers.png";
import image5 from "../../Asset/voice.png";
import image6 from "../../Asset/consistency.png";
import image7 from "../../Asset/Plagiarism.png";
const StrugglesSm = () => {
  const data = [
    {
      _id: "1",
      image: image1,
      text: "Typing long messages, emails and",
      text1: "documents, accurately and quickly?",
    },
    {
      _id: "2",
      image: image2,
      text: "Constantly correcting grammar,",
      text1: "punctuation, and formatting errors?",
    },
    {
      _id: "3",
      image: image3,
      text: "Spending too much time editing or",
      text1: "rewriting content?",
    },
    {
      _id: "4",
      image: image4,
      text: "Facing language barriers with voice",
      text1: "transcriptions?",
    },
    {
      _id: "5",
      image: image5,
      text: "Dealing with inconsistent voice",
      text1: "typing accuracy?",
    },
    {
      _id: "6",
      image: image6,
      text: "Maintaining consistency in tone and",
      text1: "style across different types of writing?",
    },
    {
      _id: "7",
      image: image7,
      text: "Plagiarism concerns when writing",
      text1: "essays or content?",
    },
  ];

  return (
    <div className="md:hidden block">
      <div className="flex justify-center items-center mt-14 mb-9 ">
        <p className="lsm:text-[1.5rem] text-[1.55rem] font-clash font-medium">
          Do You{" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Struggle
          </span>{" "}
          With ?
        </p>
      </div>
      <div className="w-[100%] sm-425:pl-3 pl-1 ">
        <Swiper
          modules={[Navigation, Autoplay, FreeMode, Pagination]}
          navigation={false}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            stopOnLastSlide: false,
          }}
          loop={true}
          breakpoints={{
            375: {
              slidesPerView: 1.3,
            },
            425: {
              slidesPerView: 1.4,
              spaceBetween: 10,
            },

            640: {
              slidesPerView: 1.8,
              spaceBetween: 10,
            },
            767: {
              slidesPerView: 2.2,
              spaceBetween: 10,
            },
          }}
        >
          {data &&
            data.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="flex justify-center">
                  <div className="flex flex-col justify-between sm:w-[300px] w-[280px] sm:h-[140px] h-[130px] px-4 py-6 border border-[#888888]/50 rounded-[1.2rem] bg-[#161616]">
                    <div>
                      <img className="w-6 max-h-8" src={item.image} alt="" />
                    </div>
                    <div className="flex flex-col sm:text-[.9rem] text-[.8rem] font-medium font-montserrat">
                      <span>{item.text}</span>
                      <span>{item.text1}</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default StrugglesSm;
