import React from "react";
// import video from "../../Asset/Animation1.gif";
import "../../App.css";
const VideoSection = () => {
  return (
    <div className="w-full relative overflow-hidden">
      <div className="md:h-[70px] h-[50px] w-full absolute bg-gradient-to-t to-[#1c1c1c] from-transparent"></div>
      <video
        className="bg-transparent w-full "
        src="/video/Main File_2.mp4"
        autoPlay
        loop
        muted
        playsInline
        webkit-playsinline="true"
      />
      <div className="md:h-[70px] h-[50px] w-full bottom-0 absolute bg-gradient-to-t from-[#1c1c1c] to-transparent"></div>

      {/* <div className="flex justify-center items-center scale-75">
        <img src={video} className="w-[100%] ml-10" alt="" />
      </div> */}
    </div>
  );
};

export default VideoSection;
