import React from "react";
import "./Hero.css";
import { Typewriter } from "react-simple-typewriter";

const Hero = () => {
  return (
    <div className="flex flex-col justify-center items-center md:mt-40 mt-24 mx-auto">
      <h1 className="hidden sm:block lg:text-[3rem] md:text-[1.9rem] text-[1.1rem] font-medium font-clash  md:w-[80%] w-[90%] text-center ">
        <p>
          State-of-the-art{" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Voice Typing
          </span>{" "}
          powered by
        </p>{" "}
        <p>ChatGPT and Whisper AI</p>
      </h1>

      <h1 className="block sm:hidden text-[1.55rem] font-medium font-clash  w-[93%] text-center ">
        <p>
          State-of-the-art{" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Voice <br /> Typing
          </span>{" "}
          powered by
        </p>{" "}
        <p>ChatGPT and Whisper AI</p>
      </h1>
      <div className="container lg:my-10 md:my-6 mb-10 mt-6  lg:text-[1.5rem] md:text-[1rem] sm:text-[.6rem] text-[.47rem] font-montserrat hidden md:flex items-center justify-center">
        <div className="animation overflow-hidden md:h-[100px] h-[55px] flex flex-col items-center lg:gap-20 md:gap-14 gap-9">
          <div className="first">
            <div className="text-center ">
              <p className="block">Flawless voice typing!</p>
              <p className="block">
                {" "}
                Simply speak, and let our AI handle the rest.
              </p>
            </div>
          </div>
          <div className="second">
            <div className=" text-center">
              <p>
                Powered by the precision of Whisper API and enhanced by the
                intelligence
              </p>
              <p>
                of ChatGPT, your voice becomes flawlessly formatted text—every
                time
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden flex justify-center items-center sm:w-[75%] w-[90%] mx-auto my-4">
        <div className="text-center text-[1rem]">
          <Typewriter
            words={[
              "Flawless voice typing! Simply speak, and let our AI handle the rest.",
              "Powered by the precision of Whisper API and enhanced by the intelligence of ChatGPT, your voice becomes flawlessly formatted text—every time",
            ]}
            loop={0}
            cursor
            cursorStyle="|"
            typeSpeed={100}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
