import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ScrollAnimation = () => {
  useEffect(() => {
    gsap.utils.toArray(".paragraph").forEach((paragraph) => {
      gsap.fromTo(
        paragraph,
        { scale: 0, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: paragraph,
            start: "top 100%",
            end: "top 50%",
            scrub: true,
          },
        }
      );
    });
  }, []);

  // useEffect(() => {
  //   gsap.utils.toArray(".paragraphsm").forEach((paragraph) => {
  //     gsap.fromTo(
  //       paragraph,
  //       { scale: 1, opacity: -1, color: "#ffffff00" },
  //       {
  //         scale: 1,
  //         opacity: 0.1,
  //         duration: 5,
  //         color: "white",
  //         scrollTrigger: {
  //           trigger: paragraphsm,
  //           start: "top 100%",
  //           end: "top 50%",
  //           scrub: true,
  //         },
  //       {
  //         scale: 1,
  //         opacity: 1,
  //         duration: 5,
  //         color: "white",
  //         scrollTrigger: {
  //           trigger: paragraphsm,
  //           start: "top 100%",
  //           end: "top 50%",
  //           scrub: true,
  //         },
  //       }
  //     );
  //   });
  // }, []);
  useEffect(() => {
    gsap.utils.toArray(".paragraphsm").forEach((paragraph) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: paragraph,
          start: "top 100%",
          end: "top 50%",
          scrub: true,
        },
      });

      tl.to(paragraph, { opacity: 0.16, duration: 2 });
      tl.to(paragraph, { opacity: 0.26, duration: 2 });
      tl.to(paragraph, { opacity: 0.46, duration: 2 });
      tl.to(paragraph, { opacity: 0.66, duration: 2 });
      tl.to(paragraph, { opacity: 0.86, duration: 2 });
      tl.to(paragraph, { opacity: 1, duration: 2 });
    });
  }, []);
  return (
    <div>
      <div className=" hidden md:flex justify-center items-center flex-col lg:leading-[4.3rem] md:leading-[3rem] sm:leading-[2rem] leading-[1.5rem] lg:my-28 md:my-20 my-16 lg:text-[1.65rem] md:text-[1.2rem] sm:text-[.65rem] text-[.5rem] font-montserrat lg:gap-14 md:gap-12 sm:gap-10 gap-6 ">
        <p className="text-center font-normal paragraph">
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            ZeroTyping AI
          </span>{" "}
          turns your voice into perfectly transcribed text <br /> with unmatched
          accuracy, every time.
        </p>
        <p className="text-center font-medium paragraph">
          and it doesn't stop there —{" "}
        </p>
        <p className="text-center font-normal paragraph">
          Integrate ChatGPT’s powerful AI to refine your output based on your
          own <br />
          custom preferences. Whether it's tone, grammar, or even your own{" "}
          <br />
          customised dictionary, {" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            ZeroTyping AI
          </span>{" "}
          delivers the exact output you need.
        </p>
      </div>

      {/* for mobile */}
      <div className="md:hidden flex  flex-col w-[90%] text-[] mx-auto sm:leading-[2rem]  leading-[1.8rem] my-14  sm:text-[1.4rem] text-[1.1rem] font-montserrat lg:gap-14 md:gap-12 sm:gap-10 gap-6 ">
        <p className=" font-normal ">
          <span className="paragraphsm bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            ZeroTyping AI
          </span>{" "}
          <span className="paragraphsm">turns your voice </span>
          <span className="paragraphsm">into perfectly </span>
          <span className="paragraphsm">transcribed text</span>
          <span className="paragraphsm"> with unmatched</span>
          <span className="paragraphsm"> accuracy,</span>
          <span className="paragraphsm"> every time.</span>
        </p>
        <p className=" font-medium paragraphsm">and it doesn't stop there — </p>
        <p className=" font-normal ">
          <span className="paragraphsm">Integrate ChatGPT’s powerful AI</span>
          <span className="paragraphsm"> to refine your output based on</span>
          <span className="paragraphsm"> your own custom preferences.</span>
          <span className="paragraphsm">
            {" "}
            Whether it's tone, grammar, or even your
          </span>
          <span className="paragraphsm"> own customised dictionary, </span>
          <span className="paragraphsm">
            <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
              ZeroTyping AI
            </span>{" "}
            delivers the exact output you need.
          </span>
        </p>
      </div>
    </div>
  );
};

export default ScrollAnimation;
