import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { toast } from "react-toastify";
import success from "../../Asset/success.png";
import error from "../../Asset/error.png";

const Contact = () => {
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  const getauthtoken = async () => {
    try {
      const response = await fetch("https://zerotyping.ai/backend/proxy/zoho", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      const result = await response.json();

      if (response.ok) {
        console.log("Token refreshed successfully");
      } else {
        setMessage(`Error: ${result.message || "Unable to refresh token."}`);
        toast.error(`Error: ${result.message || "Unable to refresh token."}`);
        // alert(message);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const sendToZohoBigin = async () => {
    if (!lastName || !email) {
      setMessage("Please fill out all fields.");
      toast.error("Please fill out all fields.");
      return;
    }
    setLoading(true);
    setMessage("");

    const data = {
      data: [{ Last_Name: lastName, Email: email }],
    };

    try {
      const response = await fetch("https://zerotyping.ai/backend/proxy/zoho", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage("Successfully added to the waiting list!");
        setIsOpenSuccess(true);
        setLastName("");
        setEmail("");
      } else {
        setMessage(
          `Error: ${result.message || "Unable to add to the waiting list."}`
        );
        setIsOpenError(true);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setIsOpenError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="flex justify-center  items-center flex-row lg:gap-4 md:gap-2.5 gap-2">
        <input
          className="bg-[#313131] lg:w-[full] md:w-[70%] w-[50%] md:text-[1rem] sm:text-[.7rem] text-[.45rem] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] sm:rounded-lg rounded-md lg:h-[4rem] md:h-[3rem] h-2rem"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          placeholder="First Name"
        />
        <input
          className="bg-[#313131] lg:w-[full] md:w-[70%] w-[50%] md:text-[1rem] sm:text-[.7rem] text-[.45rem] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] sm:rounded-lg rounded-md lg:h-[4rem] md:h-[3rem] h-2rem"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="Email"
        />
        <button
          onClick={() => {
            sendToZohoBigin();
          }}
          disabled={loading}
          className="flex items-center justify-center lg:gap-3 md:gap-2 gap-1 lg:h-[4rem] md:h-[3rem] h-2rem md:py-0 py-[9px] lg:w-full w-[80%] md:rounded-[1.2rem] sm:rounded-lg rounded-md bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] lg:px-6 md:px-2 px-1 lg:text-[1.2rem] md:text-[.9rem] sm:text-[.65rem] text-[.45rem] font-medium"
        >
          {loading ? (
            "Submitting..."
          ) : (
            <>
              Join our Waiting List!
              <FaArrowRightLong />
            </>
          )}
        </button>
      </div>
      {isOpenSuccess && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-[#323232B2] backdrop-blur-md w-[21rem] p-5 flex flex-col justify-center items-center gap-4 rounded-xl">
            <div className=" w-[full] flex justify-center items-center flex-col gap-3">
              <img src={success} className="w-16 " alt="" />
              <span className=" capitalize text-[#00FF1E] font-semibold leading-none">
                success!
              </span>
              <span className=" text-center">
                Successfully added to the waiting list!
              </span>
            </div>
            <div className="w-full">
              <button
                onClick={() => setIsOpenSuccess(false)}
                className=" bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white w-full flex justify-center items-center leading-none py-3 rounded-xl "
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      {isOpenError && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-sm">
          <div className="bg-[#323232B2] backdrop-blur-md w-[21rem] p-5 flex flex-col justify-center items-center gap-4 rounded-xl">
            <div className=" w-[full] flex justify-center items-center flex-col gap-3">
              <img src={error} className="w-16 " alt="" />
              <span className=" capitalize text-[#f04349] font-semibold leading-none">
                error!
              </span>
              <span className=" text-center">
                Unable to add to the waiting list.
              </span>
            </div>
            <div className="w-full">
              <button
                onClick={() => setIsOpenError(false)}
                className=" bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white w-full flex justify-center items-center leading-none py-3 rounded-xl "
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
