import React from "react";
import Contact from "../contact/Contact";
import { FaInstagram } from "react-icons/fa6";
import { RiFacebookFill } from "react-icons/ri";

const Footer = () => {
  return (
    <div
      id="footer"
      className="lg:mx-12 mx-4 flex flex-col lg:gap-10 md:gap-8 gap-6 mt-20 md:mb-10 mb-4"
    >
      <div>
        <p className="text-start lg:text-[2.55rem] md:text-[2rem] sm:text-[1.5rem] text-[1.2rem] font-medium font-clash">
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            ZeroTyping AI:
          </span>{" "}
          Coming Soon to Android, iOS, Windows, and Mac - Seamless voice-to-text
          across all your devices.
        </p>
      </div>
      <div className="flex justify-between md:items-end md:flex-row flex-col  md:gap-2 gap-4">
        <div className=" flex justify-center flex-col font-montserrat items-start gap-6 border border-[#484848] lg:p-4 md:p-3 p-3 w-fit rounded-3xl bg-[#262626] ">
          <p className="text-start lg:text-[1.2rem] md:text-[1rem] sm:text-[.75rem] text-[.53rem] font-medium ">
            Sign up to the waiting list below and be the first to test our{" "}
            <br />
            exciting new productivity solution. ?
          </p>
          <Contact />
        </div>
        <div className=" flex md:justify-center items-center lg:gap-6 gap-3">
          <RiFacebookFill className="lg:size-16 md:size-12 size-10 rounded-2xl border cursor-pointer bg-[#262626] border-[#484848] lg:p-3 p-2" />
          <FaInstagram className="lg:size-16 md:size-12 size-10 rounded-2xl border cursor-pointer bg-[#262626] border-[#484848] lg:p-3 p-2" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
