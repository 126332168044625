import Hero from "./component/hero/Hero";
import Navbar from "./component/Navbar/Navbar";
import VideoSection from "./component/VideoSection/VideoSection";
import LanchingSection from "./component/LanchingSection/LanchingSection";
import Struggles from "./component/Struggles/Struggles";
import Footer from "./component/Footer/Footer";
import ScrollAnimation from "./component/ScrollAnimation/ScrollAnimation";
import Dasboard from "./component/Dasboard/Dasboard";
import EveryOne from "./component/EveryOne/EveryOne";
import StrugglesSm from "./component/Struggles/StrugglesSm";
import Slider from "./component/EveryOne/Slider";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        progressClassName="toastProgress"
        className={"custom-toast-container"}
      />
      <div
        id="hero"
        className="bg-[#1c1c1c]  min-h-screen text-white text overflow-y-auto scrollbar-hide"
      >
        <Navbar />
        <Hero />
        <VideoSection />
        <LanchingSection />
        <Struggles />
        <StrugglesSm />
        <ScrollAnimation />
        <Dasboard />
        <EveryOne />
        <Slider />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
